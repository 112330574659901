import { render, staticRenderFns } from "./ShippingConfig.vue?vue&type=template&id=705033eb&scoped=true"
import script from "./ShippingConfig.vue?vue&type=script&lang=js"
export * from "./ShippingConfig.vue?vue&type=script&lang=js"
import style0 from "./ShippingConfig.vue?vue&type=style&index=0&id=705033eb&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705033eb",
  null
  
)

export default component.exports